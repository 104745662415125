import React from "react";
import Divider from "../common/Divider";
import { TradingViewEmbed, widgetType } from "react-tradingview-embed";
import "./Harmony.css";

const HarmonyChart = () => {
  return (
    <>
      <Divider
        iconClass="chart area icon"
        description="Price Chart and Comps"
      />
      <TradingViewEmbed
        widgetType={widgetType.TICKER_TAPE}
        widgetConfig={{
          symbols: [
            {
              description: "ONE (in USDT)",
              proName: "BINANCE:ONEUSDT",
            },
            {
              description: "ONE (in BTC)",
              proName: "BINANCE:ONEBTC",
            },
            {
              description: "ONE (in BNB)",
              proName: "BINANCE:ONEBNB",
            },
            {
              description: "ONE (in Huobi Token)",
              proName: "HUOBI:ONEHT",
            },
            {
              description: "BTC (in USDT）",
              proName: "BINANCE:BTCUSDT",
            },
            {
              description: "ETH (in USDT)",
              proName: "BINANCE:ETHUSDT",
            },
            {
              description: "XRP (in USDT)",
              proName: "BINANCE:XRPUSDT",
            },
            {
              description: "ADA (in USDT)",
              proName: "BINANCE:ADAUSDT",
            },
            {
              description: "ATOM (in USDT)",
              proName: "BINANCE:ATOMUSDT",
            },
          ],
          colorTheme: "light",
          isTransparent: false,
          displayMode: "compact",
          locale: "en",
        }}
      />

      <div className="tradingview-market-full">
        <TradingViewEmbed
          widgetType={widgetType.ADVANCED_CHART}
          widgetConfig={{
            height: 610,
            width: "95vw",
            symbol: "BINANCE:ONEUSDT",
            interval: "D",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: "tradingview_2497a",
          }}
        />
        <TradingViewEmbed
          widgetType={widgetType.MARKET_DATA}
          widgetConfig={{
            width: "100%",
            height: "450",
            dateRange: "3m",
            symbolsGroups: [
              {
                name: "Indices",
                originalName: "Indices",
                symbols: [
                  {
                    name: "BINANCE:ONEUSDT",
                    displayName: "ONE (in USDT)",
                  },
                  {
                    name: "BINANCE:ONEBTC",
                    displayName: "\tONE (in BTC)",
                  },
                  {
                    name: "BINANCE:ONEBNB",
                    displayName: "ONE (in BNB)",
                  },
                  {
                    name: "HUOBI:ONEHT",
                    displayName: "ONE (in Huobi Token)",
                  },
                ],
              },
            ],
            locale: "en",
          }}
        />
      </div>

      <div className="tradingview-market-mobile">
        <TradingViewEmbed
          widgetType={widgetType.SYMBOL_OVERVIEW}
          widgetConfig={{
            symbols: [
              ["ONE (in USDT)", "BINANCE:ONEUSDT|3m"],
              ["ONE (in BTC)", "BINANCE:ONEBTC|3m"],
              ["ONE (in BNB)", "BINANCE:ONEBNB|3m"],
              ["ONE (in Huobi Token)", "HUOBI:ONEHT|3m"],
            ],
            chartOnly: false,
            width: "100%",
            height: "400",
            locale: "en",
            colorTheme: "light",
            gridLineColor: "#f0f3fa",
            trendLineColor: "#2196f3",
            fontColor: "#787b86",
            underLineColor: "#e3f2fd",
            isTransparent: false,
            autosize: false,
            container_id: "tradingview_3c58f",
          }}
        />
        <TradingViewEmbed
          widgetType={widgetType.MARKET_OVERVIEW}
          widgetConfig={{
            colorTheme: "light",
            dateRange: "3m",
            showChart: false,
            locale: "en",
            largeChartUrl: "",
            isTransparent: false,
            width: "100%",
            height: "300",
            tabs: [
              {
                title: "Indices",
                symbols: [
                  {
                    s: "BINANCE:ONEUSDT",
                    d: "ONE (in USDT)",
                  },
                  {
                    s: "BINANCE:ONEBTC",
                    d: "ONE (in BTC)",
                  },
                  {
                    s: "BINANCE:ONEBNB",
                    d: "ONE (in BNB)",
                  },
                  {
                    s: "HUOBI:ONEHT",
                    d: "ONE (in Huobi Token)",
                  },
                ],
                originalTitle: "Indices",
              },
            ],
          }}
        />
      </div>
    </>
  );
};

export default HarmonyChart;
