import React from "react";
import Constants from "../../config/Constants";
import Divider from "../common/Divider";
import HarmonyUtils from "./HarmonyUtils";

const StakingSearchBar = ({
  parentState,
  filterState,
  searchName,
  onSearchBarChange,
  onToggleElectedFilter,
}) => {
  const dividerDescription = [
    parentState.loadingState,
    parentState.displayList.length,
    "Staking Pool =",
    HarmonyUtils.getReadableEposStatus(filterState.eposStatus).toUpperCase(),
  ].join(" ");

  return (
    <>
      <Divider iconClass="chart bar icon" description={dividerDescription} />
      <div className="ui fluid center aligned grid" style={{ paddingBottom: "20px" }}>
        <div className="row">
          {/* <div className="ui stackable center aligned two column grid">
            <div className="middle aligned row">
              <div className="column">
                <div className="ui icon">
                  <i aria-hidden="true" className="search icon"></i>
                  Type in a Name or Address
                </div>
                <form className="ui form" onSubmit={(e) => e.preventDefault()}>
                  <div className="ui fluid search">
                    <div className="ui icon input">
                      <input
                        type="text"
                        tabIndex="0"
                        size="80"
                        className="prompt"
                        autoComplete="off"
                        value={searchName}
                        onChange={onSearchBarChange}
                        placeholder="e.g. KysenPool -or- one1ctwewx0pmg8k0tc8vnx4guyq9jm7dwz5k98tlm"
                      />
                      <i aria-hidden="true" className="search icon"></i>
                    </div>
                  </div>
                </form>
              </div>
              <div className="column">
                <div className="ui icon">
                  <i aria-hidden="true" className="filter icon"></i>
              Filter
            </div> */}
          <div className="column">
            <div className="ui small buttons">
              <button
                className="ui compact positive button"
                onClick={onToggleElectedFilter}
                value={Constants.validatorState.elected.eposStatus}
              >
                {Constants.validatorState.elected.readableEposStatus.toUpperCase()}
              </button>
              <div className="or"></div>
              <button
                className="ui compact yellow button"
                onClick={onToggleElectedFilter}
                value={Constants.validatorState.notElected.eposStatus}
              >
                {Constants.validatorState.notElected.readableEposStatus.toUpperCase()}
              </button>
              <div className="or"></div>
              <button
                className="ui compact negative button"
                onClick={onToggleElectedFilter}
                value={Constants.validatorState.outOfService.eposStatus}
              >
                {Constants.validatorState.outOfService.readableEposStatus.toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default StakingSearchBar;
