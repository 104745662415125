import React from "react";
import EllipsisText from "react-ellipsis-text";
import HarmonyUtils from "./HarmonyUtils";
import PuffLoader from "react-spinners/PuffLoader";
import "./Harmony.css";

const ValidatorCard = ({ onValidatorSelect, data, onFavValidatorSelect }) => {
  const {
    key,
    imgSrc,
    onMissingSrc,
    name,
    details,
    dim,
    address,
    rate,
    isFav,
  } = data.validator;
  const {
    "epos-status": eposStatus,
    "total-delegation": rawTotalDelegation,
    uptime,
    "num-stakers": numStakers,
    "reward-rate": rewardRate,
  } = data;

  const rewardRateShown =
    rewardRate < 0 ? (
      <PuffLoader
        color={"#3D5686"}
        css={"margin: auto;"}
        size={20}
        loading="true"
      />
    ) : (
      rewardRate + "%"
    );

  const readableTotalStaked = HarmonyUtils.formatTotalStaked(
      rawTotalDelegation
    ),
    readableValidatorRate = HarmonyUtils.formatCommissionRate(rate),
    readableEposStatus = HarmonyUtils.getReadableEposStatus(eposStatus),
    electedClass = HarmonyUtils.getElectedClassName(eposStatus);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onValidatorSelect(address);
      }}
      className="validator-card ui card"
    >
      <div className="extra content">
        <div className="ui divided equal width grid">
          <div className="row">
            <div className="column">
              <div className="ui center aligned row">
                <nobr>
                  <i aria-hidden="true" className="black signal icon"></i>
                  Live APR
                </nobr>
              </div>
              <div className="ui center aligned row">{rewardRateShown}</div>
            </div>
            <div className="column">
              <div className="ui center aligned row">
                <i aria-hidden="true" className={electedClass} />
                Status
              </div>
              <div className="ui center aligned row">
                {readableEposStatus.toUpperCase()}
              </div>
            </div>
            <div className="column">
              <div className="ui center aligned row">
                <i
                  aria-hidden="true"
                  className="red money icon"
                  title="Commission Rate"
                />
                Comm.
              </div>
              <div className="ui center aligned row">
                {readableValidatorRate}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div>
          <img
            className="ui mini left middle aligned rounded spaced image"
            key={key}
            src={imgSrc}
            onError={onMissingSrc}
            alt={name}
            height={dim}
            width={dim}
          />
          <EllipsisText text={name} length={30} />
          <span
            onClick={(e) => {
              e.stopPropagation();
              onFavValidatorSelect(name, address);
            }}
          >
            {isFav ? (
              <i
                className="star icon yellow"
                style={{
                  margin: "auto",
                  float: "right",
                  fontSize: "18px",
                }}
              />
            ) : (
              <i
                className="star icon outline"
                style={{
                  margin: "auto",
                  float: "right",
                  fontSize: "18px",
                }}
              />
            )}
          </span>
        </div>
        {/* <div className="meta">
          <MiddleEllipsis>
            <span className="validator-address">{address}</span>
          </MiddleEllipsis>
        </div> */}
        <div className="description" title={address}>
          <EllipsisText text={details} length={100} />
        </div>
      </div>
      <div className="extra content">
        <div className="ui divided equal width grid">
          <div className="row">
            <div className="column">
              <div className="ui center aligned row">
                <i
                  aria-hidden="true"
                  className="orange ticket icon"
                  alt="Total Delegation"
                />
                Staked
              </div>
              <div className="ui center aligned row">
                {readableTotalStaked.toUpperCase()}
              </div>
            </div>
            <div className="column">
              <div className="ui center aligned row">
                <i
                  aria-hidden="true"
                  className="green users icon"
                  alt="Total Delegation"
                />
                Stakers
              </div>
              <div className="ui center aligned row">{numStakers}</div>
            </div>
            <div className="column">
              <div className="ui center aligned row">
                <i
                  aria-hidden="true"
                  className="blue mixcloud icon"
                  alt="Uptime"
                />
                Uptime
              </div>
              <div className="ui center aligned row">{uptime}%</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="extra content">
          <div className="ui two buttons">
            <button className="ui blue basic button">Delegate</button>
            <button className="ui green basic button">Claim</button>
          </div>
        </div> */}
    </div>
  );
};

export default ValidatorCard;
