import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Navigation from "./common/Navigation";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Cookie from "./common/Cookie";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App ui padded fluid segment">
          <Header />
            <Navigation />
          <Footer />
        </div>
        <Cookie />
      </Router>
    );
  }
}

export default App;
