import React from "react";
import numeral from "numeral";
import harmony from "../../api/harmony";
import Spinner from "../common/Spinner";
import PuffLoader from "react-spinners/PuffLoader";

class HarmonyNavNetwork extends React.Component {

  state = {
    networkInfo: {
      'total-supply': "12600000000.000000000000000000",
      'circulating-supply': "6721663882.352932800000000000",
      'epoch-last-block': 3702783,
      'total-staking': 3.5823433354677953e+27,
      'median-raw-stake': "10570666666666666666666666.666666666666666666",
    },
    latestHeader: {
      blockNumber: 3698997,
      epoch: 205,
      timestamp: "2020-06-20 06:37:13 +0000 UTC",
      unixtime: 1592635033,
    },
    display: {
      formattedTime: null,
      estMinsToEpoch: null,
      estEpochFormattedTime: null,
    },
    isLoading: true,
  }

  getStakingInfo = async () => {
    var networkResponse = await harmony.get("/hmyv2_getStakingNetworkInfo");
    if (networkResponse.data && networkResponse.data["total-supply"]) {
      this.setState({ networkInfo: networkResponse.data });
      var response = await harmony.get("/hmyv2_latestHeader");
      if (response.data.blockNumber !== this.state.latestHeader.blockNumber) {
        if (typeof response.data.unixtime == 'number' &&
          typeof response.data.blockNumber == 'number') {
          clearInterval(this.clockTimerId);
          const unixtime = response.data.unixtime,
            formattedTime = this.formatIntlDate(unixtime * 1000),
            estSecsToEpoch = Math.round((parseInt(networkResponse.data["epoch-last-block"]) - response.data.blockNumber) * 5),
            estEpochUnixTime = response.data.unixtime + estSecsToEpoch,
            estEpochFormattedTime = this.formatIntlDate(estEpochUnixTime * 1000);
          this.setState({
            latestHeader: response.data,      // this is a gotcha... it's not response.result
            display: {
              unixtime: unixtime,
              formattedTime: formattedTime,
              estMinsToEpoch: Math.round(estSecsToEpoch / 60),
              estEpochFormattedTime: estEpochFormattedTime,
            },
            isLoading: false,
          });
        }
      }
    }
  }

  formatIntlDate = (timestamp) => {
    var date = new Date(timestamp);
    var options = { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  networkTimer = () => {
    this.getStakingInfo();
  }

  clockTimer = () => {
    if (this.state.display && this.state.display.unixtime) {
      var display = Object.assign({}, this.state.display);
      display.unixtime += 1;
      display.formattedTime = this.formatIntlDate(display.unixtime * 1000);
      this.setState({ display: display });
    }
  }

  componentDidMount() {
    this.getStakingInfo();
    this.clockTimerId = setInterval(this.clockTimer.bind(this), 995);
    this.networkTimerId = setInterval(this.networkTimer.bind(this), 9900);
  }

  componentWillUnmount() {
    clearInterval(this.networkTimerId);
    clearInterval(this.clockTimerId);
  }

  render() {
    var spinner = <></>;
    if (this.state.isLoading) {
      spinner = <Spinner message="Loading Network Stats..." />;
    }

    return (
      <>
        <PuffLoader color={"#3D5686"} css={"float: right; position: fixed; top: 20px; right: 20px; margin-top: 0px; z-index: 1;"} size={36} loading="true" />
        <div className="ui desktop-xp very relaxed stackable three column grid">
          {spinner}
          <div className="row">
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Total Staked</div>
                  <div className="value">
                    <i aria-hidden="true" className="money alternate icon"></i>{" "}
                    {numeral(this.state.networkInfo["total-staking"] / Math.pow(10, 18)).format("0.000a")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Circulating Supply</div>
                  <div className="value">
                    <i aria-hidden="true" className="refresh icon"></i>{" "}
                    {numeral(this.state.networkInfo["circulating-supply"]).format("0.000a")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">% Staked vs. Supply</div>
                  <div className="value">
                    <i aria-hidden="true" className="lock icon"></i>{" "}
                    {numeral(this.state.networkInfo["total-staking"] / Math.pow(10, 18) / this.state.networkInfo["circulating-supply"]).format("0.00%")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Current Block</div>
                  <div className="value">
                    {numeral(this.state.latestHeader.blockNumber).format("0,0")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Epoch Ends at Block</div>
                  <div className="value">
                    {numeral(this.state.networkInfo["epoch-last-block"]).format("0,0")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Current Epoch</div>
                  <div className="value">
                    <i aria-hidden="true" className="sun icon"></i>{" "}
                    {numeral(this.state.latestHeader.epoch).format("0")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Current Network Time</div>
                  <div className="value">
                    {this.state.display.formattedTime}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Estimated Epoch End</div>
                  <div className="value">
                    {this.state.display.estEpochFormattedTime}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Minutes to Next Epoch</div>
                  <div className="value">
                    <i aria-hidden="true" className="clock icon"></i>{" "}
                    {numeral(this.state.display.estMinsToEpoch).format(0, 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ui mobile-xp two column grid">
          {spinner}
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Total Staked
            </div>
            <div className="ui left aligned column">
              <i aria-hidden="true" className="money alternate icon"></i>{" "}
              {numeral(this.state.networkInfo["total-staking"] / Math.pow(10, 18)).format("0.000a")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Circulating Supply
            </div>
            <div className="ui left aligned column">
              <i aria-hidden="true" className="refresh icon"></i>{" "}
              {numeral(this.state.networkInfo["circulating-supply"]).format("0.000a")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              % Staked vs. Supply
            </div>
            <div className="ui left aligned column">
              <i aria-hidden="true" className="lock icon"></i>{" "}
              {numeral(this.state.networkInfo["total-staking"] / Math.pow(10, 18) / this.state.networkInfo["circulating-supply"]).format("0.00%")}
            </div>
          </div>
          <div className="ui divider"></div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Current Epoch
            </div>
            <div className="ui left aligned column">
              <i aria-hidden="true" className="sun icon"></i>{" "}
              {numeral(this.state.latestHeader.epoch).format("0")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Current Block
            </div>
            <div className="ui left aligned column">
              {numeral(this.state.latestHeader.blockNumber).format("0,0")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Current Network Time
            </div>
            <div className="ui left aligned column">
              {this.state.display.formattedTime}
            </div>
          </div>
          <div className="ui divider"></div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Minutes to Next Epoch
            </div>
            <div className="ui left aligned column">
              <i aria-hidden="true" className="clock icon"></i>{" "}
              {numeral(this.state.display.estMinsToEpoch).format(0, 0)}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Epoch Ends at Block
            </div>
            <div className="ui left aligned column">
              {numeral(this.state.networkInfo["epoch-last-block"]).format("0,0")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="ui right aligned column">
              Estimated Epoch End
            </div>
            <div className="ui left aligned column">
              {this.state.display.estEpochFormattedTime}
            </div>
          </div>
        </div>
      </>
    );
  }

}

export default HarmonyNavNetwork;