import Constants from "../../config/Constants";

class CommonUtils {
  static parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  // See https://docs.netlify.com/configure-builds/environment-variables/#declare-variables
  static getAppName() {
    const queryProps = this.parseQuery(window.location.search);
    console.log("REACT_APP_NAME",process.env.REACT_APP_NAME);
    return queryProps.app || process.env.REACT_APP_NAME || Constants.app.harmony.name;
  }
}

export default CommonUtils;