import React from "react";

const Spinner = ({message = "Loading..."}) => {
  return (
    <div className="ui active inverted transition visible dimmer">
      <div className="ui indeterminate massive text loader">
        {message}
      </div>
    </div>
  );
};

export default Spinner;
