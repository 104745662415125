import React from "react";
import './Common.css';

const Divider = (props) => {
  return (
    <div className="ui divider-spacing horizontal divider">
      <h4 className="ui header">
        <i aria-hidden="true" className={props.iconClass}></i>
        {props.description}
      </h4>
    </div>
  );
};

export default Divider;
