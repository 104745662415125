import React from "react";
import CardanoCTA from "./CardanoCTA";

const CardanoNavHome = () => {
  return (
    <div className="ui equal width stackable grid">
      <div className="row">
        <div className="left floated eight wide column">
          <div className="ui column grid">
            <div className="ui column">
              <img
                src="/images/cardano.png"
                className="ui large rounded image"
                alt="Cardano"
              />
            </div>
          </div>
        </div>
        <div className="right floated eight wide column">
          <div className="ui column grid"></div>
          <div className="ui center aligned column grid">
            <div className="ui hero-description flexed container">
              <p align="justify">
                Cardano is a decentralized public blockchain and cryptocurrency project
                and is fully open source.Cardano is developing a smart contract platform
                which seeks to deliver more advanced features than any protocol previously
                developed.It is the first blockchain platform to evolve out of a scientific
                philosophy and a research - first driven approach.The development team
                consists of a large global collective of expert engineers and researchers
              </p>
            </div>
          </div>
          <div className="ui column grid"><br /></div>
          <div className="ui center aligned column grid">This site is brought to you by</div>
          <div className="ui center aligned column grid">
            <CardanoCTA />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardanoNavHome;
