import React from "react";
import numeral from "numeral";
import coingecko from "../../api/coingecko";
import Spinner from "../common/Spinner";

class HarmonyNavMarket extends React.Component {

  state = {
    network: "harmony",
    currency: "usd",
    token: "ONE",
    harmony: {
      usd: 0.0048908,
      usd_market_cap: 32874299.06997223,
      usd_24h_vol: 7987635.098070864,
      usd_24h_change: 0.3747252196483337,
      last_updated_at: 1592681349
    },
    isLoading: true,
  }

  getMarketStats = async () => {
    const params = {
      ids: this.state.network,
      vs_currencies: this.state.currency,
      include_market_cap: true,
      include_24hr_vol: true,
      include_24hr_change: true,
      include_last_updated_at: true,
    };
    var queryParams = [];
    for (let [key, value] of Object.entries(params)) {
      queryParams.push([key, value].join('='));
    };
    var response = await coingecko.get("/simple/price?" + queryParams.join('&'));
    if (response.data && response.data.harmony) {
      this.setState({ harmony: response.data.harmony, isLoading: false });
    }
  }

  formatIntlDate = (timestamp) => {
    var date = new Date(timestamp);
    var options = { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  refreshTimer = () => {
    this.getMarketStats();
  }

  componentDidMount() {
    this.getMarketStats();
    this.refreshTimerId = setInterval(this.refreshTimer.bind(this), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimerId);
  }


  render() {
    var spinner = <></>;
    if (this.state.isLoading) {
      spinner = <Spinner message="Loading Market Stats..." />;
    }

    return (
      <>
        <div className="ui desktop-xp six column grid">
          {spinner}
          <div className="row">
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Token</div>
                  <div className="value">
                    <i aria-hidden="true" className="money bill alternate outline icon"></i>{" "}
                    {this.state.token}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Price (in {this.state.currency})</div>
                  <div className="value">
                    <i aria-hidden="true" className="dollar icon"></i>{" "}
                    {numeral(this.state[this.state.network][this.state.currency]).format("$0,0.000000")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="column ui mini statistic">
                  <div className="label">Market Cap ({this.state.currency})</div>
                  <div className="value">
                    <i aria-hidden="true" className="pie chart icon"></i>{" "}
                    {numeral(this.state[this.state.network][this.state.currency + "_market_cap"]).format("$0,0")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="column ui mini statistic">
                  <div className="label">24-hr Change</div>
                  <div className="value">
                    <i aria-hidden="true" className="random icon"></i>{" "}
                    {numeral((this.state[this.state.network][this.state.currency + "_24h_change"] / 100)).format("0.0%")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">24-hr Vol ({this.state.currency})</div>
                  <div className="value">
                    <i aria-hidden="true" className="chart area icon"></i>{" "}
                    {numeral(this.state[this.state.network][this.state.currency + "_24h_vol"]).format("$0,0.000a")}
                  </div>
                </div>
              </div>
            </div>
            <div className="center aligned column">
              <div className="ui content">
                <div className="ui mini statistic">
                  <div className="label">Last updated</div>
                  <div className="value">
                    <i aria-hidden="true" className="clock outline icon"></i>{" "}
                    {this.formatIntlDate(this.state[this.state.network].last_updated_at * 1000)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ui mobile-xp two column grid">
          {spinner}
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="right aligned column">
              Token
              </div>
            <div className="left aligned column">
              <i aria-hidden="true" className="money bill alternate outline icon"></i>{" "}
              {this.state.token}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="right aligned column">
              Price (in {this.state.currency.toUpperCase()})
            </div>
            <div className="left aligned column">
              <i aria-hidden="true" className="dollar icon"></i>{" "}
              {numeral(this.state[this.state.network][this.state.currency]).format("$0,0.000000")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="right aligned column">
              Market Cap ({this.state.currency.toUpperCase()})
            </div>
            <div className="left aligned column">
              <i aria-hidden="true" className="pie chart icon"></i>{" "}
              {numeral(this.state[this.state.network][this.state.currency + "_market_cap"]).format("$0,0")}
            </div>
          </div>
          <div className="ui divider"></div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="right aligned column">
              24-hr Change
            </div>
            <div className="left aligned column">
              <i aria-hidden="true" className="random icon"></i>{" "}
              {numeral((this.state[this.state.network][this.state.currency + "_24h_change"] / 100)).format("0.0%")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="right aligned column">
              24-hr Vol ({this.state.currency.toUpperCase()})
            </div>
            <div className="left aligned column">
              <i aria-hidden="true" className="chart area icon"></i>{" "}
              {numeral(this.state[this.state.network][this.state.currency + "_24h_vol"]).format("$0,0.000a")}
            </div>
          </div>
          <div className="row" style={{padding: "0.5rem"}}>
            <div className="right aligned column">
              Last updated
            </div>
            <div className="left aligned column">
              <i aria-hidden="true" className="clock outline icon"></i>{" "}
              {this.formatIntlDate(this.state[this.state.network].last_updated_at * 1000)}
            </div>
          </div>
        </div>
      </>
    );
  }

}

export default HarmonyNavMarket;