import React from "react";
import axios from 'axios';

class CardanoNavStaking extends React.Component {

  apiCall = () => axios.create({
    baseURL: "https://faucet.ff.dev.cardano.org",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Accept',
      'Content-Type': 'application/json',
      'User-Agent': 'curl/7.64.1',
      'Accept': '*/*',
    },
  });

  state = {
    faucetAddr: '',
  };

  onFaucetChange = (e) => {
    this.setState({ faucetAddr: e.target.value });
    console.log(e.target.value);
  }

  onFaucetSubmit = async (e) => {
    e.preventDefault();
    console.log(this.state.faucetAddr);

    const faucet = axios.create({
      baseURL: 'https://faucet.ff.dev.cardano.org/',
      timeout: 20,
      headers: {
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
      },
    });
    faucet.post("/send-money/" + this.state.faucetAddr, {
    }).then((response) => {
      console.log(response)
    }).catch((error) => {
      console.log(error);
    })
  }

  render() {
    return (
      <div className="ui fluid grid">
        <div className="ui middle aligned two wide column">
          Get Tokens
        </div>
        <div className="ui fourteen wide column">
          <form className="ui form" onSubmit={this.onFaucetSubmit}>
            <div className="ui fluid icon input">
              <input type="text" onChange={this.onFaucetChange} placeholder="e.g. 00568575086ce22304445efe0d0d8c9e70420cd435aa65df41afd53e90daae27538a55edbc8587be292b3b85019291873c8b196d417e1585ede6d8ac08976caa07" />
              <i aria-hidden="true" className="lightning icon"></i>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CardanoNavStaking;
