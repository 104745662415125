import React from "react";
import EllipsisText from "react-ellipsis-text";
import HarmonyUtils from "./HarmonyUtils";
import PuffLoader from "react-spinners/PuffLoader";
import "./Harmony.css";

const ValidatorRow = ({ onValidatorSelect, onFavValidatorSelect, data }) => {
  const {
    key,
    imgSrc,
    onMissingSrc,
    name,
    details,
    dim,
    address,
    rate,
    isFav,
  } = data.validator;

  const {
    "epos-status": eposStatus,
    "total-delegation": rawTotalDelegation,
    uptime,
    "current-epoch-performance": currentPerformance,
    "num-stakers": numStakers,
    "reward-rate": rewardRate,
  } = data;

  const rewardRateShown =
    rewardRate < 0 ? (
      <PuffLoader
        color={"#3D5686"}
        css={"margin: auto;"}
        size={20}
        loading="true"
      />
    ) : (
      rewardRate + "%"
    );

  const readableTotalStaked = HarmonyUtils.formatTotalStaked(
      rawTotalDelegation
    ),
    readableValidatorRate = HarmonyUtils.formatCommissionRate(rate),
    readableEposStatus = HarmonyUtils.getReadableEposStatus(eposStatus),
    electedClass = HarmonyUtils.getElectedClassName(eposStatus),
    epochCurrentUptime = HarmonyUtils.getCurrentUptime(currentPerformance);

  return (
    <tr className="validator-row">
      <td className="ui center aligned">
        <div
          onClick={() => {
            onFavValidatorSelect(name, address);
          }}
        >
          {isFav ? (
            <i className="star icon yellow" style={{ margin: "auto" }} />
          ) : (
            <i className="star icon outline" style={{ margin: "auto" }} />
          )}
        </div>
      </td>
      <td className="">
        <div className="" onClick={() => onValidatorSelect(address)}>
          <span>
            <h4 className="">
              <img
                className="ui mini left middle aligned rounded spaced image"
                key={key}
                src={imgSrc}
                onError={onMissingSrc}
                alt={name}
                height={dim}
                width={dim}
              />
              <EllipsisText text={name} length={30} />
            </h4>
          </span>
          <div
            className="description"
            title={address}
            onClick={() => onValidatorSelect(address)}
          >
            <EllipsisText text={details} length={200} />
          </div>
          {/* <MiddleEllipsis>
            <span className="validator-address">{address}</span>
          </MiddleEllipsis> */}
        </div>
      </td>
      <td className="ui center aligned">
        <i aria-hidden="true" className={electedClass} />
        {readableEposStatus.toUpperCase()}
      </td>
      <td className="ui center aligned">{rewardRateShown}</td>
      <td className="ui center aligned">{readableValidatorRate}</td>
      <td className="ui center aligned">{readableTotalStaked.toUpperCase()}</td>
      <td className="ui center aligned">{numStakers}</td>
      <td className="ui center aligned">
        {uptime}%<br />({epochCurrentUptime}%)
      </td>
    </tr>
  );
};

export default ValidatorRow;
