import React from "react";
import ValidatorRow from "./ValidatorRow";
import ValidatorCard from "./ValidatorCard";
import HarmonyUtils from "./HarmonyUtils";
import Constants from "../../config/Constants";
import Spinner from "../common/Spinner";
import "./Harmony.css";
import "../common/Common.css";

const ValidatorList = ({
  parentState,
  filterState,
  onValidatorSelect,
  onSortByCommission,
  onSortByStakes,
  onSortByNumStakers,
  onSortByUptime,
  onFavValidatorSelect,
  onSortByRewardRate,
}) => {
  if (parentState.loadingState === "Found") {
    const dim = 32;
    var pos = 0;

    const newValidatorList = parentState.displayList.map((data) => {
      const validator = data.validator;
      validator.pos = ++pos;
      validator.dim = dim;
      validator.imgSrc = [
        "https://raw.githubusercontent.com/harmony-one/validator-logos/master/validators/",
        validator.address,
        ".jpg",
      ].join("");
      validator.missingSrc = [
        "https://avatars.dicebear.com/api/jdenticon/",
        validator.address,
        ".svg?width=",
        validator.dim,
        "&height=",
        validator.dim,
      ].join("");
      validator.onMissingSrc = (e) => {
        e.target.src = validator.missingSrc;
      };
      return data;
    });

    const validatorRows = newValidatorList.map((data) => {
      return (
        <ValidatorRow
          key={data.validator.address}
          onValidatorSelect={onValidatorSelect}
          onFavValidatorSelect={onFavValidatorSelect}
          data={data}
        />
      );
    });
    const validatorCards = newValidatorList.map((data) => {
      return (
        <ValidatorCard
          key={data.validator.address}
          onValidatorSelect={onValidatorSelect}
          onFavValidatorSelect={onFavValidatorSelect}
          data={data}
        />
      );
    });

    const numStakersClassName = HarmonyUtils.setSortClassName(
        Constants.validatorSort.sortBy.numStakers,
        filterState
      ),
      commissionClassName = HarmonyUtils.setSortClassName(
        Constants.validatorSort.sortBy.commission,
        filterState
      ),
      stakesClassName = HarmonyUtils.setSortClassName(
        Constants.validatorSort.sortBy.stakes,
        filterState
      ),
      uptimeClassName = HarmonyUtils.setSortClassName(
        Constants.validatorSort.sortBy.uptime,
        filterState
      ),
      rewardRateClassName = HarmonyUtils.setSortClassName(
        Constants.validatorSort.sortBy.rewardRate,
        filterState
      );

    return (
      <div className="ui sixteen wide column">
        <table className="validator-table ui striped definition selectable table">
          <thead className="ui sixteen wide column">
            <tr className="">
              <th className=""></th>
              <th className="ui">Staking Pool</th>

              <th
                className="ui center aligned"
                title="An indicator of whether a Validator has qualified to validate for the Harmony blockchain based on meeting the Effective Proof-of-Stake requirements"
              >
                <div className="validator-sort">
                  <i aria-hidden="true" className="grey toggle on icon" />
                  <br />
                  Election
                  <br />
                  Status
                </div>
              </th>
              <th
                className="ui center aligned"
                title="Live Annual Percentage Reward (APR) yield, minus commissions, for this validator over a 10-minute period"
              >
                <div className="validator-sort" onClick={onSortByRewardRate}>
                  <i aria-hidden="true" className="gray signal icon"></i>
                  <br />
                  <nobr>Live APR</nobr>
                  <br />
                  <nobr>
                    &nbsp; Yield
                    <i
                      aria-hidden="true"
                      className={rewardRateClassName}
                      alt="Sort Reward"
                    />
                  </nobr>
                </div>
              </th>
              <th
                className="ui center aligned"
                title="The commission rate charged by this validator deducted from delegators' staking rewards"
              >
                <div className="validator-sort" onClick={onSortByCommission}>
                  <i aria-hidden="true" className="red money icon" />
                  <br />
                  <nobr>Commission &nbsp;</nobr>
                  <br />
                  <nobr>
                    &nbsp; Rate
                    <i
                      aria-hidden="true"
                      className={commissionClassName}
                      title="Sort Commission Rate"
                    />
                  </nobr>
                </div>
              </th>
              <th
                className="ui center aligned"
                title="Total number of tokens staked with this validator"
              >
                <div className="validator-sort" onClick={onSortByStakes}>
                  <i aria-hidden="true" className="orange ticket icon" />
                  <br />
                  <nobr>Total &nbsp;</nobr>
                  <br />
                  <nobr>
                    &nbsp;&nbsp; Staked
                    <i
                      aria-hidden="true"
                      className={stakesClassName}
                      title="Sort Stakes"
                    />
                  </nobr>
                </div>
              </th>
              <th
                className="ui center aligned"
                title="Number of unique wallet addresses that staked with this validator"
              >
                <div className="validator-sort" onClick={onSortByNumStakers}>
                  <i aria-hidden="true" className="green users icon" />
                  <br />
                  Num of
                  <br />
                  <nobr>
                    &nbsp;&nbsp; Stakers
                    <i
                      aria-hidden="true"
                      className={numStakersClassName}
                      title="Sort by Number of Delegators"
                    />
                  </nobr>
                </div>
              </th>
              <th
                className="ui center aligned"
                title="Shows the percentage of blocks signed by this validator over it's lifetime (and in the current epoch)"
              >
                <div className="validator-sort" onClick={onSortByUptime}>
                  <i aria-hidden="true" className="blue mixcloud icon" />
                  <br />
                  <nobr>
                    &nbsp; Uptime
                    <i
                      aria-hidden="true"
                      className={uptimeClassName}
                      alt="Sort Uptime"
                    />
                  </nobr>
                  <br />
                  (Current)
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="">{validatorRows}</tbody>
        </table>
        <div className="validator-cards">
          <div className="ui centered stackable column fluid cards">
            {validatorCards}
          </div>
        </div>
      </div>
    );
  } else {
    return <Spinner message="Loading Validators List..." />;
  }
};

export default ValidatorList;
