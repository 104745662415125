import React from "react";
// import "./Cardano.css";

const stakeNow = (e) => {
  e.preventDefault();
  window.open(
    "https://ff.pooltool.io/pool/ce09c3009cb367e3d32c237923e9993547175f8207a25faf6f1a73628d741a23",
    "_new"
  );
};

const CardanoCTA = () => {
  return (
    <button className="ui orange big cta button" onClick={stakeNow}>
      <span role="img" aria-label="thunder">
      ⚡
      </span>{" "}
      KysenPool Thunder{" "}
      <span role="img" aria-label="thunder">
      ⚡
      </span>
    </button>
  );
};

export default CardanoCTA;
