import React from "react";
import HarmonyCTA from "./HarmonyCTA";
import "../common/Common.css"

const HarmonyNavHome = () => {
  return (
    <div className="ui equal width stackable grid">
      <div className="row">
        <div className="left floated eight wide column">
          <div className="ui column grid">
            <div className="ui column">
              <img
                src="/images/harmony.png"
                className="ui large rounded image"
                alt="Harmony Consensus for the 10 Billion"
              />
            </div>
          </div>
        </div>
        <div className="right floated eight wide column">
          <div className="ui column grid"></div>
          <div className="ui center aligned column grid">
            <div className="ui hero-description flexed container">
              <p align="justify">
                Harmony is a fast and secure blockchain for decentralized
                applications. The production mainnet supports 4 shards of 1000
                nodes, producing blocks in 8 seconds with finality.
                </p>
              <p align="justify">
                Harmony's Effective Proof-of-Stake (EPoS) reduces centralization
                while supporting stake delegation, reward compounding and
                double-sign slashing.
                </p>
            </div>
          </div>
          <div className="ui column grid"><br /></div>
          <div className="ui center aligned column grid">This site is brought to you by</div>
          <div className="ui center aligned column grid">
            <HarmonyCTA />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarmonyNavHome;
