import React from "react";
import Constants from "../../config/Constants";
import HarmonyUtils from "./HarmonyUtils";
import "./Harmony.css";

const StakingSearchHeader = ({
  filterState,
  onSortByCommission,
  onSortByStakes,
  onSortByNumStakers,
  onSortByUptime,
  onSortByRewardRate,
}) => {

  const numStakersClassName = HarmonyUtils.setSortClassName(Constants.validatorSort.sortBy.numStakers, filterState),
    rewardRateClassName = HarmonyUtils.setSortClassName(Constants.validatorSort.sortBy.rewardRate, filterState),
    commissionClassName = HarmonyUtils.setSortClassName(Constants.validatorSort.sortBy.commission, filterState),
    stakesClassName = HarmonyUtils.setSortClassName(Constants.validatorSort.sortBy.stakes, filterState),
    uptimeClassName = HarmonyUtils.setSortClassName(Constants.validatorSort.sortBy.uptime, filterState);

  return (
    <div className="validator-cards">
      <div className="ui center aligned sixteen wide column grid" style={{ paddingBottom: 20 }}>
        <button className="ui mini button"
          onClick={onSortByRewardRate}>
          <i aria-hidden="true" className="gray signal icon" />
          {" "}
          <i aria-hidden="true" className={rewardRateClassName} title="Sort by Live APR Yield" />
        </button>
        <button className="ui mini button"
          onClick={onSortByCommission}>
          <i aria-hidden="true" className="red money icon" title="Commission Rate (the higher means the worse your return rate will be)" />
          {" "}
          <i aria-hidden="true" className={commissionClassName} title="Sort by Commission Rate" />
        </button>
        <button className="ui mini button"
          onClick={onSortByStakes}>
          <i aria-hidden="true" className="orange ticket icon" title="Total Stakes" />
          {" "}
          <i aria-hidden="true" className={stakesClassName} title="Sort by Total Stakes" />
        </button>
        <button className="ui mini button"
          onClick={onSortByNumStakers}>
          <i aria-hidden="true" className="green users icon" title="Number of Delegators" />
          {" "}
          <i aria-hidden="true" className={numStakersClassName} title="Sort by Number of Delegators" />
        </button>
        <button className="ui mini button"
          onClick={onSortByUptime}>
          <i aria-hidden="true" className="blue mixcloud icon" title="Sort by Uptime" />
          {" "}
          <i aria-hidden="true" className={uptimeClassName} title="Sort by Uptime" />
        </button>
      </div>
    </div>
  );
};

export default StakingSearchHeader;
