import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  return (
    <CookieConsent
      location={"bottom"}
      buttonText={"Accept"}
      declineButtonText={"Decline"}
      enableDeclineButton={true}
      cookieName="__kysenpool__consent"
      buttonClasses={"ui basic inverted button"}
      declineButtonClasses={"ui black basic inverted button"}
      style={{ "background": "black", "padding": "20px" }}
      expires={365}
    >
      <p>
        This website stores cookies on your computer. These cookies are used
        to collect information about how you interact with our website and allow
        us to remember you. We use this information in order to improve and customize
        your browsing experience and for analytics and metrics about our visitors
        both on this website and other media. To find out more about the cookies
        we use, see our Privacy Policy.
      </p>
      <p>
        If you decline, your information won’t be tracked when you visit this
        website. A single cookie will be used in your browser to remember your
        preference not to be tracked.
      </p>
    </CookieConsent>

  )
}

export default Cookie;