import React from "react";
import { Tab } from "semantic-ui-react";
import { Route, NavLink, Switch } from "react-router-dom";

import CommonUtils from "../common/CommonUtils";
import Constants from "../../config/Constants";
import HarmonyNavHome from "../harmony/HarmonyNavHome";
import HarmonyNavMarket from "../harmony/HarmonyNavMarket";
import HarmonyNavNetwork from "../harmony/HarmonyNavNetwork";
import HarmonyNavStaking from "../harmony/HarmonyNavStaking";
import HarmonyChart from "../harmony/HarmonyChart";
import CardanoNavHome from "../cardano/CardanoNavHome";
import CardanoNavStaking from "../cardano/CardanoNavStaking";

class Navigation extends React.Component {
  state = {
    home: <div />,
    market: <div />,
    staking: <div />,
    chart: <div />,
  };

  panes = [
    {
      menuItem: {
        as: NavLink,
        id: "tab1",
        content: "Home",
        to: "/",
        exact: true,
        key: "home",
      },
      pane: (
        <Route
          path="/"
          exact
          render={() => (
            <Tab.Pane attached={false}>
              {this.state.home}
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab2",
        content: "Staking",
        to: "/staking",
        exact: true,
        key: "staking",
      },
      pane: (
        <Route
          path={["/staking","/network"]}
          exact
          render={() => (
            <Tab.Pane attached={false}>
              {this.state.network}
              {this.state.staking}
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        id: "tab3",
        content: "Chart",
        to: "/chart",
        exact: true,
        key: "chart",
      },
      pane: (
        <Route
          path={["/chart","/stats"]}
          exact
          render={() => (
            <Tab.Pane attached={false}>
              {this.state.market}
              {this.state.chart}
            </Tab.Pane>
          )}
        />
      ),
    },
  ];

  componentDidMount = () => {
    switch (CommonUtils.getAppName()) {
      case Constants.app.cardano.name:
        this.setState({
          home: <CardanoNavHome />,
          staking: <CardanoNavStaking />,
        });
        break;
      case Constants.app.harmony.name:
      default:
        this.setState({
          home: <HarmonyNavHome />,
          market: <HarmonyNavMarket />,
          network: <HarmonyNavNetwork />,
          staking: <HarmonyNavStaking />,
          chart: <HarmonyChart />,
        });
        break;
    }
  };

  render() {
    return (
      <Switch>
        <Tab
          menu={{
            secondary: true,
            pointing: true,
          }}
          renderActiveOnly={false}
          activeIndex={-1}
          panes={this.panes}
        />
      </Switch>
    );
  }
}

export default Navigation;
