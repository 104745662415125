
const Constants = {
    app: {
        harmony: {
            name: "harmony",
            baseURL: process.env.REACT_APP_BASE_URL || 'https://harmony.kysenpool.io',
            gaId: "UA-140899487-2",
            valAddr: "one1ctwewx0pmg8k0tc8vnx4guyq9jm7dwz5k98tlm",
        } ,
        cardano: {
            name: "cardano",
            baseURL: process.env.REACT_APP_BASE_URL || 'https://cardano.kysenpool.io',
            gaId: "UA-140899487-3",
        },
    },
    server: {
        baseURL: process.env.REACT_APP_API_URL || 'https://harmony-api.kysenpool.io',
        coingeckoURL : process.env.REACT_APP_COINGECKO_URL || 'https://api.coingecko.com/api/v3',
    },
    validatorState: {
        elected: {
            eposStatus: "currently elected",
            readableEposStatus: "elected",
        },
        notElected: {
            eposStatus: "eligible to be elected next epoch",
            readableEposStatus: "not elected",
        },
        outOfService: {
            eposStatus: "not eligible to be elected next epoch",
            readableEposStatus: "no service",
        },
    },
    validatorSort: {
        sortBy: {
            stakes: "total-delegation",
            uptime: "uptime",
            numStakers: "num-stakers",  // This is a custom field made here
            commission: "commission",   // This is a custom field made here
            rewardRate: "reward-rate",  // This is a custom field made here
        },
        sortOrder: {
            desc: "desc",
            asc: "asc",
            none: "none",
        },
    },
    validatorHistory: {
        maxRecords: 50,
        early: 10,
        midstage: 20,
        mature: 30,
    },
}

export default Constants;