import Constants from "../../config/Constants";
import numeral from "numeral";

class HarmonyUtils {
  static getReadableEposStatus(eposStatus) {
    return (eposStatus === Constants.validatorState.elected.eposStatus
      ? Constants.validatorState.elected.readableEposStatus
      : eposStatus === Constants.validatorState.notElected.eposStatus
        ? Constants.validatorState.notElected.readableEposStatus
        : Constants.validatorState.outOfService.readableEposStatus);
  }

  static getCurrentUptime(currentPerformance) {
    return currentPerformance &&
      currentPerformance["current-epoch-signing-percent"] &&
      currentPerformance["current-epoch-signing-percent"]["current-epoch-signing-percentage"] ?
      (currentPerformance["current-epoch-signing-percent"]["current-epoch-signing-percentage"] * 100.).toFixed(2) : "0.00";
  }

  static getElectedClassName(eposStatus) {
    return eposStatus === Constants.validatorState.elected.eposStatus
      ? "green toggle on icon"
      : eposStatus === Constants.validatorState.notElected.eposStatus
        ? "yellow toggle off icon"
        : "red toggle off icon";
  }

  static formatTotalStaked(rawTotalDelegation) {
    return numeral(
      Math.round(rawTotalDelegation / Math.pow(10, 18))
    ).format("0.00a")
  }

  static formatCommissionRate(rate) {
    return numeral(rate).format("0.00%");
  }

  static setSortClassName(sortType, filterState) {
    if (filterState.sortBy === sortType) {
      switch (filterState.sortOrder) {
        case Constants.validatorSort.sortOrder.asc:
          return "triangle up icon";
        case Constants.validatorSort.sortOrder.desc:
          return "triangle down icon";
        default:
      }
    }
    return "sort icon";
  };

}

export default HarmonyUtils;