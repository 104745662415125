import React from "react";
import "./Harmony.css";
import Constants from "../../config/Constants"

const stakeNow = (e) => {
  e.preventDefault();
  window.open(
    "https://staking.harmony.one/validators/" + Constants.app.harmony.valAddr,
    "_new"
  );
};

const HarmonyCTA = () => {
  return (
    <button className="ui orange big cta button" onClick={stakeNow}>
      <span role="img" aria-label="meridian globe">
        🌐
      </span>{" "}
      KysenPool Dragon{" "}
      <span role="img" aria-label="dragon">
        🐉
      </span>
    </button>
  );
};

export default HarmonyCTA;
