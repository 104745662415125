import React from "react";
import harmony from "../../api/harmony";
import StakingSearchBar from "./StakingSearchBar";
import StakingSearchHeader from "./StakingSearchHeader";
import ValidatorList from "./ValidatorList";
import Constants from "../../config/Constants";
import store from "store";

class HarmonyNavStaking extends React.Component {
  // This is for display states
  state = {
    loadingState: "Loading",
    validatorList: [],
    displayList: [],
    favValidator: [],
    liveRewards: [],
    blockHistory: [],

    // placeholder values
    latestHeader: {
      blockNumber: 3698997,
      epoch: 205,
      timestamp: "2020-06-20 06:37:13 +0000 UTC",
      unixtime: 1592635033,
    },
  };

  // This is for non-display states
  filterState = {
    searchName: "",
    eposStatus: Constants.validatorState.elected.eposStatus,
    sortBy: Constants.validatorSort.sortBy.stakes,
    sortOrder: Constants.validatorSort.sortOrder.desc,
  };

  sortDisplay = (filteredList) => {
    // console.log("Sorting...");
    filteredList.sort((a, b) => {
      if (this.filterState.sortOrder === Constants.validatorSort.sortOrder.asc)
        return a[this.filterState.sortBy] - b[this.filterState.sortBy];
      else return b[this.filterState.sortBy] - a[this.filterState.sortBy];
    });
  };

  filterDisplay = async () => {
    // console.log("Filtering...");
    const validatorList = this.state.validatorList,
      { searchName, eposStatus } = this.filterState,
      filteredList = [];

    validatorList.forEach((elem) => {
      if (
        (elem.validator.name.toLowerCase().indexOf(searchName) >= 0 ||
          elem.validator.address.indexOf(searchName) >= 0) &&
        elem["epos-status"] === eposStatus
      ) {
        filteredList.push(elem);
      }
    });

    this.sortDisplay(filteredList);

    const favoritedList = this.highlightFav(filteredList);
    this.setState({ displayList: favoritedList, loadingState: "Found" });
  };

  onToggleElectedFilter = (e) => {
    this.setState({ loadingState: "Filtering through" });
    this.filterState.eposStatus = e.target.value;
    this.filterDisplay();
  };

  onSearchBarChange = (e) => {
    this.setState({ loadingState: "Searching through" });
    this.filterState.searchName = e.target.value.toLowerCase();
    this.filterDisplay();
  };

  onSortByCommission = (e) => {
    this.setSortOrder(Constants.validatorSort.sortBy.commission);
    this.filterDisplay();
  };

  onSortByStakes = (e) => {
    this.setSortOrder(Constants.validatorSort.sortBy.stakes);
    this.filterDisplay();
  };

  onSortByNumStakers = (e) => {
    this.setSortOrder(Constants.validatorSort.sortBy.numStakers);
    this.filterDisplay();
  };

  onSortByUptime = (e) => {
    this.setSortOrder(Constants.validatorSort.sortBy.uptime);
    this.filterDisplay();
  };

  onSortByRewardRate = (e) => {
    this.setSortOrder(Constants.validatorSort.sortBy.rewardRate);
    this.filterDisplay();
  };

  setSortOrder = (sortType) => {
    if (this.filterState.sortBy === sortType) {
      this.filterState.sortBy = sortType;
      this.filterState.sortOrder =
        this.filterState.sortOrder === Constants.validatorSort.sortOrder.asc
          ? Constants.validatorSort.sortOrder.desc
          : Constants.validatorSort.sortOrder.asc;
    } else {
      // Always start with descending order first
      this.filterState.sortBy = sortType;
      this.filterState.sortOrder =
        sortType === Constants.validatorSort.sortBy.commission
          ? Constants.validatorSort.sortOrder.asc
          : Constants.validatorSort.sortOrder.desc;
    }
  };

  onValidatorSelect = (address) => {
    window.open(
      ["https://staking.harmony.one/validators/mainnet/", address].join(""),
      address   // open a unique window for each validator
    );
  };

  highlightFav = (filteredList) => {
    const { favValidator } = this.state;
    var favList = [],
      nonFavList = [];
    filteredList.forEach((object) => {
      if (
        favValidator.find((fav) => fav.address === object.validator.address)
      ) {
        object.validator.isFav = true; // mark as favourite validator
        favList.push(object);
      } else {
        object.validator.isFav = false;
        nonFavList.push(object);
      }
    });
    return [...favList, ...nonFavList];
  };

  onFavValidatorSelect = (name, address) => {
    const { favValidator } = this.state;
    if (favValidator.find((fav) => fav.address === address)) {
      // star favValidator click
      let newUpdateFavValidator = favValidator.filter(
        (fav) => fav.address !== address
      );
      this.setState({ favValidator: newUpdateFavValidator }, () => {
        this.updateLocalStorageFavList(newUpdateFavValidator);
        this.filterDisplay();
      });
    } else {
      // if the validator not in the fav list
      favValidator.unshift({ name, address });
      this.setState({ favValidator }, () => {
        this.updateLocalStorageFavList(favValidator);
        this.filterDisplay();
      });
    }
  };

  updateLocalStorageFavList(object) {
    store.set("favValidator", object);
  }

  getAllValidatorInformation = async () => {
    var response,
      latestHeader,
      page = -1, // for all Validators
      validatorList = [],
      validatorRewards = this.state.blockHistory;
    // console.log("Getting header");
    latestHeader = await harmony.get("/hmyv2_latestHeader");
    // console.log(latestHeader);
    if (latestHeader.data.blockNumber !== this.state.latestHeader.blockNumber) {
      // console.log("Getting hmyv2_getAllValidatorInformation");
      response = await harmony.get("/hmyv2_getAllValidatorInformation/" + page);
      // console.log("Found",response.data.length,"validators");
      response.data.forEach((elem) => {
        // console.log("Processing",elem.validator.name);
        elem.uptime =
          elem.lifetime.blocks["to-sign"] > 0
            ? (
              (elem.lifetime.blocks.signed /
                elem.lifetime.blocks["to-sign"]) *
              100
            ).toFixed(2)
            : 0;
        elem["num-stakers"] = 0; // For sorting
        elem["delegated-amount"] = 0;
        elem["delegated-rewards"] = 0;
        elem.validator.delegations.forEach((dlg) => {
          if (dlg.amount > 0 || dlg.undelegations.length > 0) {
            elem["num-stakers"]++;
            // Only add delegators that start earning, not new delegators
            if (dlg.reward > 0) {
              elem["delegated-amount"] += dlg.amount;
              elem["delegated-rewards"] += dlg.reward;
            }
            // Also include those that have undelegated this epoch
            dlg.undelegations.forEach((undlg) => {
              elem["delegated-amount"] += undlg.amount;
            });
          }
        });
        elem["commission"] = elem.validator.rate; // For sorting

        // Calculate rewards
        if (validatorRewards[elem.validator.address] === undefined) {
          validatorRewards[elem.validator.address] = [];
        }
        const rewardBlock = {
          blockNumber: latestHeader.data.blockNumber,
          unixtime: latestHeader.data.unixtime,
          rewardAccumulated: elem.lifetime["reward-accumulated"],
        };
        const vr = validatorRewards[elem.validator.address];
        if (
          vr.length === 0 ||
          vr[vr.length - 1].blockNumber < rewardBlock.blockNumber
        )
          vr.push(rewardBlock);

        if (vr.length < 2) {
          elem["reward-rate"] = -1;
        } else {
          const firstBlock = vr[0],
            lastBlock = vr[vr.length - 1],
            timeDiffInSec = lastBlock.unixtime - firstBlock.unixtime,
            rewardDiff =
              lastBlock.rewardAccumulated - firstBlock.rewardAccumulated;

          elem["reward-rate"] =
            elem["delegated-amount"] === 0
              ? "0.00"
              : timeDiffInSec <= 0
                ? -1
                : (
                  (rewardDiff / timeDiffInSec / elem["delegated-amount"]) *
                  86400 *
                  365 *
                  100 *
                  (1 - elem.validator.rate)
                ).toFixed(2);
        }

        // Optional memory management step...
        delete elem.validator.delegations;
      });
      validatorList = validatorList.concat(response.data);
      // console.log(validatorList);

      // Keep at most 50 blocks of validator list data
      if (
        this.state.blockHistory.length >= Constants.validatorHistory.maxRecords
      ) {
        this.state.blockHistory.shift(); // Remove the oldest element
      }
      // blockHistory not used in UI, hence not using setState here
      this.state.blockHistory.push({
        header: latestHeader.data,
        validatorRewards: validatorRewards,
        latestHeader: latestHeader.data,
      });

      this.setState({
        loadingState: "Found",
        validatorList: validatorList,
        latestHeader: latestHeader.data,
      });

      this.filterDisplay();
    }
  };

  refreshTimer = () => {
    this.getAllValidatorInformation();
  };

  async componentDidMount() {
    this.getAllValidatorInformation();
    this.refreshTimerId = setInterval(this.refreshTimer.bind(this), 15000);
    var favValidator;

    // initialize empty array to the local storage
    if (!store.get("favValidator")) {
      favValidator = [{ address: Constants.app.harmony.valAddr }];
      store.set("favValidator", favValidator);
    } else {
      favValidator = await store.get("favValidator");
    }
    this.setState({ favValidator });
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimerId);
  }

  render() {
    return (
      <div className="">
        <StakingSearchBar
          parentState={this.state}
          filterState={this.filterState}
          searchName={this.state.searchName}
          onSearchBarChange={this.onSearchBarChange}
          onToggleElectedFilter={this.onToggleElectedFilter}
        />
        <StakingSearchHeader
          parentState={this.state}
          filterState={this.filterState}
          onSortByCommission={this.onSortByCommission}
          onSortByNumStakers={this.onSortByNumStakers}
          onSortByStakes={this.onSortByStakes}
          onSortByUptime={this.onSortByUptime}
          onSortByRewardRate={this.onSortByRewardRate}
        />
        <ValidatorList
          parentState={this.state}
          filterState={this.filterState}
          onValidatorSelect={this.onValidatorSelect}
          onFavValidatorSelect={this.onFavValidatorSelect}
          onSortByCommission={this.onSortByCommission}
          onSortByNumStakers={this.onSortByNumStakers}
          onSortByStakes={this.onSortByStakes}
          onSortByUptime={this.onSortByUptime}
          onSortByRewardRate={this.onSortByRewardRate}
        />
      </div>
    );
  }
}

export default HarmonyNavStaking;
