import React from 'react';
import ReactGA from 'react-ga';
import CommonUtils from '../common/CommonUtils'
import Constants from "../../config/Constants";

class Header extends React.Component {
    render() {
        switch (CommonUtils.getAppName()) {
            case Constants.app.cardano.name:
                ReactGA.initialize(Constants.app.cardano.gaId);
                break;
            case Constants.app.harmony.name:
            default:
                ReactGA.initialize(Constants.app.harmony.gaId);
                break;
        }
        ReactGA.pageview(window.location.pathname + window.location.search);
        return <div />;
    }
}

export default Header;